<template>
    <div class="flex flex-col max-h-full gap-4 flex-1">
        <div class="flex items-end gap-2" v-if="showSearch">
            <Checkbox @input="$emit('selectAll', $event)" v-if="selectionEnabled" :updateSelectAllVal="updateSelectAllVal" :disabled="!listItems?.length" :isSelectAllInput="isSelectAllInput"/>
            <div class="search_container w-full">
                <Input @input="(value) => $emit('handleFilterResult', value)" placeholder="search" icon="search" />
            </div>
        </div>
        <div v-if="isAdditionalForm">
            <label>
                <input type="radio" name="collect_data_from" value="Existing Case Data" @click="handleCollectData" checked> Existing Case Data
            </label>
            <label>
                <input type="radio" name="collect_data_from" value="New Data Collection " @click="handleCollectData"> New Data Collection 
            </label>
        </div>
        <div v-if="listItems && listItems.length > 0" class="overflow-y-auto scroll-bar  rounded-lg flex flex-col h-full border-box-stroke" :class="showBorder && 'divide-y divide-var(--theme-color-box-stroke)-200 border border-base-300' + containerClass " >
            <div v-for="item,index in listItems" class="flex cursor-pointer gap-2 items-center p-2 max-w-full " :class="showHover && 'hover:bg-blue-100' " :key="index">
                <slot name="item" :item="item">
                    <span v-if="showIcon">
                        <font-awesome-icon v-if="icon" :icon="icon" class="mr-2 text-sm" :class="iconClass" />
                    </span>
                    {{ item[valueKey] }}    
                </slot>
            </div>
        </div>
        <div v-else class="flex py-10 justify-center h-full border rounded" :class="containerClass">
            <Empty :showIcon="true" text="No result found" />
        </div>
    </div>
</template>
<script>
import Input from "../../input"
import Empty from "../../empty"
import Checkbox from "@/components/checkbox";

export default {
    name: "list-view",
    components: {
        Input,
        Empty,
        Checkbox,
    },
    props: {
        listItems: {
            type: Array,
            default: () => [],
        },
        icon: {
            type: String,
            default: "",
        },
        showIcon: {
            type: Boolean,
            default: false,
        },
        iconClass: {
            type: String,
            default: "text-blue-500",
        },
        valueKey: {
            type: String,
            default: "",
        },
        containerClass: {
            type: String,
            default: "",
        },
        showSearch:{
            type:Boolean,
            default: false
        },
        showBorder:{
            type:Boolean,
            default: true
        },
        showHover:{
            type:Boolean,
            default: false
        },
        selectionEnabled: {
            type:Boolean,
            default: false
        },
        updateSelectAllVal: {
            type: Boolean,
            default: false,
        },
        isSelectAllInput: {
            type: Boolean,
        },
        isAdditionalForm:Boolean
    },
    data() {
        return {};
    },
    mounted() {
    },
    computed: {},
    methods: {
        handleCollectData($event){
            this.$emit("handleCollectData",$event.target.value);
        }
    },
};
</script>
<style scoped></style>
